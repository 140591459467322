<template>
  <div class="create-primary padding-top-layout padding-right-layout list-menu">
    <Form v-show="!showInputUnit" @getDataForm="getDataForm" :typeForm="typeForm" :dataFormDetail="dataFormDetail.data"></Form>
    <table-unit v-show="showInputUnit" @displayInputUnitScreen="displayInputUnitScreen" :typeForm="typeForm" :dataFormDetail="dataFormDetail"></table-unit>
    <div v-show="!showInputUnit" class="action-form-link" ref="scrollElement">
      <div class="return__button-responsive" :class="{ 'fixed-button-bottom': isFixedButton }">
        <div class="registration-responsive">
          <common-button class="submit-register-btn" :label="$t('primary_data_merge.button_submit')" :disabled="isDisabledForm()"
            type="colored" @action="submitForm()" v-ripple="false" :loading="isLoadingSubmit" />
        </div>
      </div>
    </div>

    <notification-popup :dialog="dialogNotification" :message="this.$t('supplier_products.popup_create_success')"
      @submit="navigateAfterSubmitSuccess" />
    <ErrorPopup :dialog="dialogError" :message="messageError" @submit="dialogError = false" />
  </div>
</template>
<script>
import Form from '@/views/products/linkToPrimaryData/formInputPrimary/form.vue';
import TableUnit from '@/views/products/linkToPrimaryData/formInputPrimary/table-unit.vue';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { ROUTES } from '@/router/constants';
import validate from '@/utils/validate';

import {
  createFormPrimary, updateFormPrimaryById, getDetailFormCfpSupplierApi
} from '@/api/product/emissionsProduct';

export default {
  components: {
    Form,
    TableUnit,
    CommonButton,
    ErrorPopup,
    NotificationPopup,
  },
  data() {
    return {
      isFixedButton: true,
      showInputUnit: false,
      dataUnit: [],
      dialogError: false,
      dialogNotification: false,
      isLoadingSubmit: false,
      messageError: '',
      formData: {},
      dataFormDetail: {}
    }
  },
  watch: {
    showInputUnit(newValue) {
      this.isFixedButton = true;
      if (newValue) {
        this.scrollToTop();
      }
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.isEdit) this.getFormPrimaryById();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    typeForm: {
      type: String,
      default: 'create',
    },
  },
  computed: {
    isEdit() {
      return this.typeForm === 'edit';
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    displayInputUnitScreen(val, dataUnit) {
      this.showInputUnit = val;
      this.dataUnit = dataUnit;
    },
    getDataForm(value) {
      if (typeof value === 'boolean') {
        this.showInputUnit = value
      } else {
        this.formData = value
      }
    },
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > scrollElementTop + 237) {
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    isDisabledForm() {
      const inputsNotRequired = ['productPeriod', 'branch_id', 'titleEdit', 'organizational', 'company'];
      const inputsRequiredEmptyValue = Object.keys(this.formData).filter((key) => {
        const isKeyNotRequired = inputsNotRequired.includes(key);
        const isEmptyValue = key === 'title' ? !this.formData[key]?.trim() : !this.formData[key];
        return !isKeyNotRequired && isEmptyValue;
      });

      const isValidEmail = validate.regexMail.test(this.formData.email);
      const validateEdit = this.formData.title === this.formData.titleEdit && this.isEdit;
      const validateDataUnitAndRadioInput =
        this.dataUnit?.length === 0 &&
        (this.formData.valueRadioSupplierInput === 1 || this.formData.valueRadioSupplierInput === undefined);
      return inputsRequiredEmptyValue.length > 0 || !isValidEmail || validateEdit || validateDataUnitAndRadioInput;
    },
    async getFormPrimaryById() {
      const res = await getDetailFormCfpSupplierApi(this.$route.params.id);
      this.dataFormDetail = res;
      this.dataUnit = res.template_unit;
    },
    submitForm() {
      this.isLoadingSubmit = true;
      if (this.isEdit) {
        this.editForm();
      } else {
        this.createForm();
      }
    },
    editForm() { 
      let payload = {
        title: this.formData.title,
      };
      
      updateFormPrimaryById(this.$route.params.id, payload)
        .then((_) => {
          this.dialogNotification = true;
        })
        .catch((error) => {
          if (error.errors?.email) {
            this.dialogError = true;
            this.messageError = error.errors.email.join('\n');
          }
        })
        .finally(() => {
          this.isLoadingSubmit = false;
        });
    },
    createForm() {
      const formData = this.formData;
      let payload = {
        title: formData.title,
        email: formData.email,
        branch_id: this.branchSelected?.id,
        cfp_product_id: formData.lotNo.id,
        cfp_boundary_id: formData.boundaryName.id,
        cfp_process_id: formData.processes.id,
        input_method: formData.valueRadioSupplierInput,
      };
      if (formData.valueRadioSupplierInput === 1) {
        payload.data = this.dataUnit;
      }
      createFormPrimary(payload)
        .then((_) => {
          this.dialogNotification = true;
        })
        .catch((error) => {
          if (error.errors?.email) {
            this.dialogError = true;
            this.messageError = error.errors.email.join('\n');
          }
        })
        .finally(() => {
          this.isLoadingSubmit = false;
        });
    },
    navigateAfterSubmitSuccess() {
      this.$router.push({ path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_FORM_PRIMARY_DATA_MERGE}` });
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/views/products/linkToPrimaryData/styles/index.scss';
</style>
